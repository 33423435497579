import 'material-symbols';
import Router from './pages/Router';

type Props = {}

const App = (props: Props) => {

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;




