const saleItems = [
    {
        price: 3.90,
        pic: "https://www.steimatzky.co.il/pub/media/catalog/product/cache/054fd023ed4beb824f3143faa6fcc008/0/5/055490002-1662877680140271.jpg",
    },
    {
        name: "",
        price: 4.00,
        pic: "https://cdn.cashcow.co.il/images/f31b37b6-76e2-411c-86f5-9d23a8810717.jpg",
    },
    {
        name: "",
        price: 10.00,
        pic: "https://m.media-amazon.com/images/I/61-PPjux-IL._AC_UF1000,1000_QL80_.jpg",
    },
    {
        name: "",
        price: 10.90,
        pic: "https://www.dalas.co.il/wp-content/uploads/2019/07/%D7%99%D7%A2%D7%94-%D7%9E%D7%A4%D7%9C%D7%A1%D7%98%D7%99%D7%A7-300x300.jpg",
    },
    {
        price: 3.90,
        pic: "https://www.steimatzky.co.il/pub/media/catalog/product/cache/054fd023ed4beb824f3143faa6fcc008/0/5/055490002-1662877680140271.jpg",
    },
    {
        name: "",
        price: 4.00,
        pic: "https://cdn.cashcow.co.il/images/f31b37b6-76e2-411c-86f5-9d23a8810717.jpg",
    },
    {
        name: "",
        price: 10.00,
        pic: "https://m.media-amazon.com/images/I/61-PPjux-IL._AC_UF1000,1000_QL80_.jpg",
    },
    {
        name: "",
        price: 10.90,
        pic: "https://www.dalas.co.il/wp-content/uploads/2019/07/%D7%99%D7%A2%D7%94-%D7%9E%D7%A4%D7%9C%D7%A1%D7%98%D7%99%D7%A7-300x300.jpg",
    },
]

export default saleItems