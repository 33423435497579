import React from 'react'

type Props = {}

const Account = (props: Props) => {
    return (
        <div>
            <p>הזמנות אחרונות</p>
            <p>הפרטים שלי</p>
            <p>כרטיסי אשראי</p>
            <p>יציאה</p>
        </div>
    )
}

export default Account